html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', Arial, sans-serif;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact {
  display: none !important;
}