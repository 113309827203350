.farm-page {
  padding: 48px 48px 0;

  &__number {
    text-align: left;
    color: #100f0c;
  }

  &__link {
    color: #4c956c;
    font-weight: 500;

    &_white {
      color: #fff;
      font-weight: 400;
    }
  }

  &__title-inactive {
    color: #8c8c8c;
  }
}

.farm-item {
  &__info {
    flex: 1;
    height: calc(100vh - 80px);
    overflow: auto;
    padding: 32px 32px 8px;
    width: 600px;
    box-sizing: border-box;
    color: #050401;
  }

  &__pie-info-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    flex-wrap: wrap;
  }

  &__pie-flex {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;

    & .farm-item__pie-info-wrap-base {
      flex-direction: column;

      & .farm-item__pie-info-wrap {
        width: 260px;
        justify-content: flex-start;
      }
    }
  }

  &__pie-info-color {
    min-width: 10px;
    min-height: 10px;
    border-radius: 100%;
  }

  &__pie {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__header {
    font-size: 1.6em;
    margin: 0 0 32px;
    text-align: center;
  }

  &__header-center {
    text-align: center;
  }

  &__map {
    flex: 2;
    height: 100%;
  }

  &__wrap {
    display: flex;
    height: calc(100vh - 80px);
  }

  &__field-info-wrap {
    display: flex;
  }

  &__field-info {
    flex: 1;
    padding: 24px;
    border: 1px solid #f3f3f3;

    &:first-of-type {
      border-left: 0;
    }

    &:last-of-type {
      border-right: 0;
    }

    & div:first-of-type {
      font-size: 1.4em;
      text-align: center;
      margin-bottom: 4px;
    }

    & div:last-of-type {
      font-size: 0.9em;
      text-align: center;
      color: #7a7a7a;
    }
  }
}