.marker {
  &__icon {
    width: 24px;
    cursor: pointer;
  }

  &__modal-info {
    margin-bottom: 4px;

    &-text {
      margin-left: 4px;
    }

    &-name {
      font-weight: 500;
    }

    &-copy {
      margin-left: 6px;
      cursor: pointer;
    }
  }
}
