.login {
  z-index: 1;
  width: 250px;
  max-height: 100%;

  &__input {
    border-radius: 4px;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    display: block;
    box-sizing: border-box;
    color: #555;
    border: 1px solid #ccc;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  }

  &__input-wrap {
    margin-bottom: 16px;
  }

  .panel {
    height: initial;
    border: none;
    border-radius: 4px;
    overflow: hidden;

    .panel-body {
      padding: 15px;
      background: white;
    }
  }

  &__header {
    background: #000;
    padding: 10px 15px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__error {
    padding-top: 10px;
    text-align: center;
    color: #600;
    font-size: 14px;
  }

  &__message {
    padding-top: 10px;
    text-align: center;
    color: #069c61;
  }

  &__btn {
    width: 100%;
    color: white;
    border-radius: 4px;
    height: 34px;
    cursor: pointer;
    background: darken(#f5cb5c, 5%);
    border: 1px solid darken(#f5cb5c, 15%);

    &:hover,
    &:focus {
      background: darken(#f5cb5c, 15%);
      border: 1px solid darken(#f5cb5c, 25%);
    }
  }

  &--wrp {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-repeat: repeat;
    background-size: cover;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      background-color: rgba(0, 0, 0, 0.5);
    }

    .password {
      position: relative;
      &-reveal {
        position: absolute;
        top: 6px;
        right: 5px;
        cursor: pointer;
        width: 20px;
        height: 20px;
        text-align: center;
        outline: none !important;

        .fa {
          color: #ccc;
        }

        &:hover {
          .fa {
            color: #333;
          }
        }
      }
    }
  }

  &__customer {
    text-align: center;
  }
}
