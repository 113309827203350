.map-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.map-container {
  position: relative;
  width: 100%;
  flex: 1;

  &__filters {
    position: absolute;
    left: 40px;
    top: 12px;
    z-index: 40;
  }

  &__field-map {
    width: 100%;
    height: 100%;
  }

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  & .zoom-viewer {
    z-index: 20;
    width: 320px;
    position: fixed;
    right: 60px;
    bottom: 24px;
    background: #fff;
    border-radius: 12px;
    font-size: 16px;
    padding: 16px;

    & table {
      width: 100%;
    }

    & .zoom-viewer__btn-link {
      color: blue;
      cursor: pointer;
    }

    & tr:not(:last-of-type) > td {
      padding-bottom: 4px;
    }
  }

  & .scroll-zoom__button {
    position: absolute;
    top: 12px;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    animation: expand ease 1s;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 16px;
    background-color: #7ddbd0;
    z-index: 30;

    &_destroy {
      top: -48px;
      animation: backExpand ease 1s;
    }
  }

  @keyframes backExpand {
    0%{top: 12px}
    100%{top: -48px;}
  }

  @keyframes expand {
    0%{top: -24px}
    100%{top: 12px;}
  }

  & .settings {
    position: absolute;
    right: 20px;
    bottom: 24px;
    z-index: 20;

    & .settings-button {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 36px;
      height: 36px;
      background-size: 36px 360px;
      background-color: rgb(249, 249, 249);
      font-size: 16px;
      opacity: 0.95;
      transition: background-color 0.16s ease-out 0s;
      cursor: pointer;
      border: 0px;
    }
  }

  & .zoom-bounds {
    position: absolute;
    right: 20px;
    top: 10px;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    z-index: 20;
  }

  & .zoom-control {
    position: relative!important;
    top: 0!important;
    right: 0!important;
    border: none!important;
    box-shadow: none!important;

    & button {
      border-radius: 50%!important;
      width: 36px!important;
      height: 36px!important;
      background-size: 36px 360px!important;

      &:last-child {
        margin-top: 18px!important;
        background-position: 0 -36px!important;
      }
    }
  }

}

.farm-item__map {
  & .scroll-zoom__button {
    position: absolute;
    top: 12px;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    animation: expand ease 1s;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 16px;
    background-color: #7ddbd0;
    z-index: 30;

    &_destroy {
      top: -48px;
      animation: backExpand ease 1s;
    }
  }
}

.map-container__field-map {

 

  & .zoom-bounds {
    position: absolute;
    right: 20px;
    top: 10px;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    z-index: 20;
  }

  & .zoom-control {
    position: relative!important;
    top: 0!important;
    right: 0!important;
    border: none!important;
    box-shadow: none!important;

    & button {
      border-radius: 50%!important;
      width: 36px!important;
      height: 36px!important;
      background-size: 36px 360px!important;

      &:last-child {
        margin-top: 18px!important;
        background-position: 0 -36px!important;
      }
    }
  }
}

.header-adaptive {
  background-color: #111;
  height: 63px;
  display: none;
}

.map-header {
  box-sizing: border-box;
  width: 100%;
  height: 80px;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #333533;
  color: #ffffff;

  &__regions {
    position: absolute;
    top: 16px;
    right: 4px;
  }

  &__lang {
    border: 0;
    background: transparent;
    border-bottom: 1px solid transparent;
    color: #fff;
    padding: 0;
    margin-right: 6px;
    cursor: pointer;

    &:hover:not([disabled]) {
      color: #00FFFF;
    }

    &[disabled] {
      border-bottom: 1px solid #fff;
      cursor: default;
    }
  }

  &__logo {
    cursor: pointer;
    font-family: 'JetBrains Mono',Arial,sans-serif;
    font-weight: 700;
    color: #ffffff;
    font-size: 30px;
  }

  &__info {
    font-size: 14px;
    margin-right: 12px;


    &-wrap {
      display: flex;
      height: 100%;
      flex: 1;
      justify-content: center;
      align-items: center;
      gap: 30px;
    }
  }

  &__nav-item {
    font-family: 'JetBrains Mono',Arial,sans-serif;
    display: inline-block;
    white-space: nowrap;
    transform: translate(-108px, -2px);
    color: #ffffff;
    font-weight: 700;
    font-size: 26px;
    padding-bottom: 2px;
    cursor: pointer;
    border-bottom: 1px solid transparent;

    &:hover {
      color: #00FFFF;
    }

    &_active {
      color: #ffffff !important;
      font-weight: 700 !important;
      cursor: default;
    
      border-bottom: 1px solid #ffffff;
    }
  }

  &__icon {
    color: #f5cb5c;
    width: 36px;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.copy-link {
  padding: 6px;
  border: 1px solid #111;
  display: inline-block;
  margin-top: 6px;
  cursor: pointer;

  &.disabled {
    opacity: 0.4;
  }
}

@media (max-width: 980px) {
  
  .map-container {
    & .scroll-zoom__button {
      top: 60px;

      &_destroy {
        top: -48px;
        animation: backExpand ease 1s;
      }
    }

    @keyframes backExpand {
      0%{top: 60px}
      100%{top: -48px;}
    }

    @keyframes expand {
      0%{top: -24px}
      100%{top: 60px;}
    }
  }
  .map-container__filters {
    left: 12px;
  }

  .header-adaptive {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-right: 20px;
    box-sizing: border-box;

    .burger {
      width: 28px;
      height: 20px;
      position: relative;
      transform: rotate(0deg);
      transition: .5s ease-in-out;
      cursor: pointer;
      margin-top: 21px;

      & span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: white;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
      }

      & span:nth-child(1) {
        top: 0px;
      }

      & span:nth-child(2),& span:nth-child(3) {
        top: 8px;
      }

      & span:nth-child(4) {
        top: 16px;
      }

      &.open span:nth-child(1) {
        top: 8px;
        width: 0%;
        left: 50%;
      }

      &.open span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &.open span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      &.open span:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
    }
  }

  @keyframes headerAnimation {
    from {background-color: white;}
    to {background-color: #333533;}
  }

  .map-header {
    flex-direction: column;
    height: 304px;
    padding: 20px 0;
    display: none;
    position: absolute;
    z-index: 9998;

    &__regions {
      position: relative;
      margin-top: 24px;
      margin-bottom: 24px;
    }


    &_active {
      display: flex;
      animation: headerAnimation 500ms;
    }

    &__logo {
      line-height: 80px;
    }

    &__nav-item {
      padding: 0;
      line-height: 41px;
      font-family: 'JetBrains Mono',Arial,sans-serif;
    }


    &__info-wrap {
      flex-direction: column;
      justify-content: flex-start;
    }

    &__nav-item {
      transform: none
    }
  }
}

.marker-popup {
  z-index: 60 !important;
  .mapboxgl-popup-content {
    background-color: #1b1b1b;
    padding: 12px;
  }
  &.mapboxgl-popup-anchor-top-left {
    top: -12px;
    left: 22px;
  }
  .mapboxgl-popup-tip {
    content: '';
    position: absolute;
    left: -8px;
    border: 8px solid transparent;
    border-top: 10px solid #1b1b1b;
  }
}
